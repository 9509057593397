import { createI18n } from "vue-i18n"
// 导入语言
import zhCnLocal from "element-plus/es/locale/lang/zh-cn"
import enLocal from "element-plus/es/locale/lang/en"
import itLocal from "element-plus/es/locale/lang/it"
// 本地语言
import zhCn from "./zh-cn"
import en from "./en"
import it from "./it"
export type messagesType = "zh-cn" | "en" | "it"
export const messages = {
  "zh-cn": {
    ...zhCnLocal,
    ...zhCn,
  },
  en: {
    ...enLocal,
    ...en,
  },
  it: {
    ...itLocal,
    ...it,
  },
}

// 创建 i18n
export const i18n = createI18n({
  legacy: false,
  globalInjection: true, // 全局模式，可以直接使用 $t
  locale: localStorage.getItem("language") || import.meta.env.VITE_LANGUAGE,
  messages: messages,
})
