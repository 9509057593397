export default {
  value: "it",
  label: "Italiano",
  user: {
    login: "Login",
    logout: "Quit",
    changeEmail: "Modifica email",
    changePwd: "Cambia password",
    verifyEmail: "Verifica email",
    bindEmail: "Email associata",
    sendCode: "ICV",
    next: "Passo successivo",
    pwd: "Password attuale",
    newPwd: "Nuova password",
    checkPwd: "Conferma password",
    sendEmail: "Invio di e-mail",
    fotgetEmail: "Password dimenticata",
    plzAccount: "Inserisci il tuo numero di conto",
    plzPwd: "Inserisci la password",
  },
  buttons: {
    login: "Login",
    search: "Search",
    reset: "reset",
    add: "aggiungi",
    delBranch: "Elimina batch",
    edit: "Modifica",
    delete: "Annulla",
    cancel: "Annulla",
    confirm: "Conferma",
    save: "Salva",
    export: "Esporta",
    import: "Importa",
    action: "Azione",
    show: "Mostra",
    hide: "Nascondi",
    refresh: "Refresh",
    switchView: "Cambia vista",
  },
  tips: {
    loading: "carico",
    success: "successo",
    error: "errore",
    title: "titolo",
    confirm: "conferma",
    cancel: "annulla",
    deleteText: "Sei sicuro di volerlo eliminare",
    debounce: "Non operare con frequenza",
    isNull: "L'opzione è vuota",
    isRequired: "Inserisci informazioni",
    copy: "copia riuscita",
    pwd: "Inserisci una password di accesso a 6-20 cifre",
    rePwd: "Inserisci nuovamente la tua password",
    checkPwd: "Inconsigenza della password!",
    verifyEmail: "Inserisci il codice di e-mail",
    verifyCode: "Inserisci il codice di verifica corretto",
    sendCode: "Il codice di verifica è stato inviato",
    sendEmail: "Email inviata",
    isBottom: "Tutti caricati",
  },
  upload: {
    success: "Caricamento riuscito",
    error: "Caricamento fallito",
    prompt1: "Solo i file in formato",
    prompt2: "possono essere caricati e non devono superare",
    limit: "Il limite è #limit, hai selezionato i file #length questa volta, aggiungi fino a #count totalmente",
    type: "Il formato corrente è #suffix, e i file caricati possono essere solo in formato #accept",
    limitMin: "La dimensione del file caricato non può essere uguale a 0k",
    limitMax: "La dimensione del file di caricamento non può superare #size",
  },
  tem: {
    name: "nome",
    templete: "modello",
    formOption: "opzione modulo",
    pageSize: "dimensione pagina",
    text: "testo",
    line: "linea",
    table: "tabella",
    img: "image",
    code128: "codice a barre",
    qrcode: "Codice QR",
    conf: "confing",
    titleContent: "contenuto",
    titleTable: "table",
    thead: "tead",
    twid: "larghezza",
    tableErr: "La somma delle larghezze della tabella non può superare il 100%",
    imgUpload: "Carica immagine",
    left: "sinistra",
    center: "centro",
    right: "destra",
    bold: "grassetto",
    size: "dimensione",
    up: "su",
    down: "giù",
    del: "elimina",
    save: "salva",
    type: "tipo",
    dict: "dict",
  },
}
