import { defineStore } from "pinia"
interface cacheStore {
  formId: string //数据id 新增为空
  formKey: string // 表单名称
  formApi: string // api
  formDict: any // 表单字典

  tableRowKey: string // 表格主键 默认id
  tableUpApi?: string // 数据更新api
  tableQueryApi: string //查询api
  tableDict: any // 表格字典
}

const cacheStore = defineStore({
  id: "cache",
  state: (): cacheStore => ({
    formId: "",
    formKey: "form",
    formApi: "",
    formDict: {},

    tableRowKey: "id",
    tableUpApi: "",
    tableQueryApi: "",
    tableDict: {},
  }),
  getters: {},
  actions: {
    setFormInfo(configInfo: { id?: string; api?: string; key?: string }) {
      const { id, api, key } = configInfo
      this.formId = id ?? ""
      this.formApi = api ?? ""
      this.formKey = key ?? "form"
    },
    setFormDict(value: any): any {
      this.formDict = value
    },

    setTableRowKey(value: string) {
      this.tableRowKey = value ?? "id"
    },
    setTableUpApi(value: string) {
      this.tableUpApi = value ?? ""
    },
    setTableQueryApi(value: string) {
      this.tableQueryApi = value ?? ""
    },
    setTableDict(value: any) {
      this.tableDict = value
    },
  },
  persist: {
    // 开启持久化
    enabled: true,
    // 选择存储方式和内容
    strategies: [
      {
        storage: sessionStorage,
        paths: ["formId", "formKey", "formApi"],
      },
    ],
  },
})

export default cacheStore
