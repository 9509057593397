<template>
  <div class="container">
    <!-- <div class="steps">
      <div class="steps__box">
        <a :class="{ active: active === 1, pass: active > 1 }" href="javascript:void 0">
          <span class="steps__icon">1</span>
          <div class="steps__title">{{ $t("user.verifyEmail") }}</div>
          <i class="steps__line"></i>
        </a>
        <a :class="{ active: active === 2, pass: active > 2 }" href="javascript:void 0">
          <span class="steps__icon">2</span>
          <div class="steps__title">{{ $t("user.bindEmail") }}</div>
        </a>
      </div>
    </div> -->
    <div class="formMain">
      <el-form :model="formData" ref="fromRef" :rules="formDataRules" size="large" class="body">
        <el-form-item label="" prop="email">
          <el-input v-model="formData.email">
            <template #prepend>
              <span type="primary" size="default">{{ $t("user.bindEmail") }}</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-input v-model="formData.code">
            <template #append>
              <el-button type="primary" size="default" @click="showPwd" v-show="codeTime === 60" v-loading="loading">
                {{ $t("user.sendCode") }}
              </el-button>
              <span v-show="codeTime !== 60">{{ codeTime }}s</span>
            </template>
          </el-input>
        </el-form-item>
      </el-form>

      <div class="body">
        <el-button type="primary" @click="debounceConfirm" class="wid" :loading="confirmLoading">
          {{ $t("user.bindEmail") }}
        </el-button>
      </div>
    </div>

    <!-- 修改密码 -->
    <el-dialog
      v-model="pwdData.show"
      :title="$t('user.pwd')"
      width="300px"
      align-center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-input v-model="pwdData.password" placeholder="" clearable></el-input>
      <el-button
        type="primary"
        @click="debounceVerifyCode"
        class="wid"
        style="margin-top: 20px"
        :loading="pwdData.loading"
        :disabled="!pwdData.loading && pwdData.password === ''"
      >
        {{ $t("buttons.confirm") }}
      </el-button>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import type { FormRules, FormInstance } from "element-plus/es"
import { UserStore } from "@/stores"
import { toast } from "@/utils/tip.js"
import { changeEmail, changeEmailCode } from "@/api/sys/user"
import { i18n } from "@/locales"
import debounce from "@/utils/debounce"

const { t } = i18n.global
interface FormDate {
  email: string
  code: string
}
const loading = ref(false)
const formData = reactive<FormDate>({
  email: "",
  code: "",
})

const fromRef = ref<FormInstance>()
const formDataRules = reactive<FormRules>({
  email: [{ required: true, trigger: "blur", type: "email" }],
  code: [{ required: true, message: "Please enter the verification code", trigger: "blur" }],
})

const pwdData = reactive({
  show: false,
  password: "",
  loading: false,
  isCode: false,
})
const showPwd = () => {
  const emailRule =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!emailRule.test(formData.email)) {
    toast.warning(t("tips.verifyEmail"))
    return
  }

  pwdData.show = true
  pwdData.loading = false
  pwdData.password = ""
  pwdData.isCode = false
}
// 验证码逻辑
const codeTime = ref(60)
let timer: NodeJS.Timeout | undefined
const timerFunc = () => {
  timer && clearInterval(timer)
  timer = setInterval(
    (function func() {
      if (codeTime.value == 1) {
        clearInterval(timer)
        codeTime.value = 60
      } else {
        codeTime.value--
      }
      return func
    })(),
    1000,
  )
}

const init = () => {
  const nowDate = new Date().getTime()
  if (sessionStorage.getItem("emailCode")) {
    const sec = (nowDate - parseInt(sessionStorage.getItem("emailCode") as string)) / 1000
    if (sec > 60) {
      codeTime.value = 60
    } else {
      codeTime.value = 60 - ~~sec
      pwdData.isCode = true
      timerFunc()
    }
  } else {
    sessionStorage.setItem("emailCode", nowDate.toString())
  }
}
init()

const verifyCode = () => {
  if (!pwdData.password) {
    toast.warning(t("user.plzPwd"))
    return
  }
  sessionStorage.setItem("emailCode", new Date().getTime().toString())

  loading.value = true
  timerFunc()

  if (!timer) return // 定时器不存在
  changeEmail({ email: formData.email, password: pwdData.password })
    .then(res => {
      pwdData.show = false
      loading.value = false
      pwdData.isCode = true
      toast.success(t("tips.sendCode"))
    })
    .catch(e => {
      codeTime.value = 60
      timer && clearInterval(timer)
      sessionStorage.removeItem("emailCode")
      loading.value = false
    })
}

const debounceVerifyCode = debounce(verifyCode)

const emit = defineEmits(["cancel"])
const confirmLoading = ref(false)

const onConfirm = () => {
  fromRef.value?.validate(valid => {
    if (valid) {
      confirmLoading.value = true
      changeEmailCode({ email: formData.email, code: formData.code })
        .then(res => {
          toast.success(t("tips.success"))
          UserStore().setToken("") // 清除token

          setTimeout(() => {
            confirmLoading.value = false
            window.location.reload()
          }, 500)
        })
        .catch(e => {
          confirmLoading.value = false
        })
    }
  })
}
const debounceConfirm = debounce(onConfirm)
</script>
<style lang="scss" scoped>
.formMain {
  // margin-top: 30px;
  padding: 5px;
  text-align: center;

  .body {
    width: 350px;
    position: relative;
    display: inline-block;
  }
}

.steps {
  width: 420px;
  text-align: center;
  a {
    float: left;
  }
  .steps__box {
    display: inline-block;
  }
  .active {
    .steps__icon {
      color: #f25d8e;
      border-color: #f25d8e;
    }
    .steps__title {
      color: #f25d8e;
    }
  }
  .pass {
    .steps__icon {
      color: #00a1d6;
      border-color: #00a1d6;
    }
    .steps__title {
      color: #00a1d6;
    }
  }
  &__icon {
    float: left;
    background-color: #fff;
    width: 20px;
    height: 20px;
    color: #ccd0d7;
    font-weight: 700;
    border-radius: 50%;
    line-height: 20px;
    border: 2px solid #ccd0d7;
    margin: 0 10px;
    text-align: center;
  }
  &__title {
    float: left;
    margin-right: 10px;
    font-size: 18px;
    color: #99a2aa;
    line-height: 24px;
  }
  &__line {
    float: left;
    height: 1px;
    background: #e5e9ef;
    width: 60px;
    margin-top: 11px;
    border-top: 1px solid #e5e9ef;
  }
}

.el-form-item {
  margin-bottom: 20px;
}
</style>
