import { UserStore } from "@/stores"
import Echo from "laravel-echo"
import io from "socket.io-client"

const initSocket = () => {
  window.io = io
  window.echo = new Echo({
    broadcaster: "socket.io",
    // host: window.location.hostname,
    host: "https://dev.wivoce.cn",
    // authEndpoint: "/api/broadcasting/auth",
    auth: {
      headers: {
        Authorization: UserStore().getToken,
      },
    },
    transports: ["websocket", "polling"],
    rememberUpgrade: true,
    withCredentia1s: true,
  })
}

export default initSocket
