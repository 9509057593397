import request from "@/utils/request"

// 登录方法
export function login(data: any) {
  return request({
    url: "/api/backend/login",
    method: "post",
    data: {
      name: data.username,
      password: data.password,
    },
  })
}

// 登录方法
export function logout() {
  return request({
    url: "/api/backend/login/logout",
    method: "post",
    data: {},
  })
}
// 修改密码
export function changePassword(data: any) {
  return request({
    url: "/api/backend/information/change_password",
    method: "get",
    data: data,
  })
}

// 新邮箱发送验证二维码
export function changeEmail(data: any) {
  return request({
    url: "/api/backend/information/changeEmail",
    method: "post",
    data: data,
  })
}

// 新邮箱验证二维码
export function changeEmailCode(data: any) {
  return request({
    url: "/api/backend/information/changeEmailCode",
    method: "post",
    data: data,
  })
}
// 找回密码，发送邮件
export function sendEmail(data: any) {
  return request({
    url: "/api/backend/login/send_email",
    method: "post",
    data: data,
  })
}
export function updatePassword(data: any) {
  return request({
    url: "/api/backend/login/update_password",
    method: "post",
    data: data,
  })
}
// 获取本地配置接口
export function getConfigData() {
  return request({
    url: "/api/backend/system/index",
    method: "get",
    data: {},
  })
}
