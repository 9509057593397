<template>
  <div class="navHeader">
    <!-- aside控制 -->
    <div @click="changeSider" class="navHeader__sign">
      <div class="navHeader__sign__icon">
        <svg-icon :icon="LayoutStore().silderStatus ? 'layout-indent' : 'layout-outdent'" size="16px"></svg-icon>
      </div>
    </div>
    <!-- 面包屑 -->
    <div class="navHeader__breadcrumb">
      <el-breadcrumb separator="/" size="large">
        <transition-group name="breadcrumb">
          <el-breadcrumb-item v-for="item in matched" :key="item.path">
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </transition-group>
      </el-breadcrumb>
    </div>
    <div class="navHeader__menu">
      <!-- <div class="navHeader__menu__item">
        <el-dropdown trigger="click" title="设置" @command="setSize" class="img-icon">
          <span>
            <svg-icon icon="setting" size="20px"></svg-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="large">大尺寸</el-dropdown-item>
              <el-dropdown-item command="default">中尺寸</el-dropdown-item>
              <el-dropdown-item command="small">小尺寸</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div> -->
      <div class="navHeader__menu__item">
        <el-select v-model="LayoutStore().language" @change="LayoutStore().setLanguage($event)">
          <el-option
            v-for="item in languageOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="navHeader__menu__item">
        <el-select v-model="UserStore().storeId" @change="changeStore" class="w150">
          <template #prefix>
            <svg-icon icon="layout-store" :size="'20px'"></svg-icon>
          </template>
          <el-option
            v-for="item in UserStore().storeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <!-- 个人功能菜单 -->
      <el-dropdown trigger="click" class="navHeader__menu__item">
        <!-- 头像 -->
        <span class="navHeader__menu__item--imgSpan">
          <!-- <ElAvatar class="navHeader__menu--img" :src="UserStore().avatarUrl" v-if="UserStore().avatarUrl"></ElAvatar> -->
          <svg-icon class="navHeader__menu--img" icon="layout-setting" size="28px"></svg-icon>
          <!-- <span class="navHeader__menu--text omit" :title="UserStore().name">{{ UserStore().name }}</span> -->
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="changeInfo">{{ $t("user.changeEmail") }}</el-dropdown-item>
            <el-dropdown-item @click="changePwd">{{ $t("user.changePwd") }}</el-dropdown-item>
            <el-dropdown-item @click="loginOut">{{ $t("user.logout") }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>

  <!-- 修改邮箱-->
  <el-dialog v-model="infoShow" :title="$t('user.changeEmail')" width="500px" destroy-on-close align-center>
    <info-item @cancel="drwCancel"></info-item>
  </el-dialog>

  <!-- 修改密码 -->
  <el-dialog v-model="pwdShow" :title="$t('user.changePwd')" width="500px" destroy-on-close align-center>
    <pwd-item @cancel="drwCancel"></pwd-item>
  </el-dialog>
</template>

<script setup lang="ts">
import { UserStore, LayoutStore } from "@/stores"
import InfoItem from "./info.vue"
import PwdItem from "./pwd.vue"
import { messages } from "@/locales"

// import NoteTip from './note-tip.vue';
const route = useRoute()
// 控制aside
const changeSider = () => {
  LayoutStore().changeAsideStatus()
}
const languageOptions = Object.entries(messages).map((item, index) => {
  return {
    value: item[1].value,
    label: item[1].label,
  }
})
// 更改默认尺寸
const setSize = (e: "" | "large" | "default" | "small") => {
  LayoutStore().setSize(e)
}
// 登出
const loginOut = () => {
  UserStore()
    .logout()
    .then(() => {
      // 重置pinia
      LayoutStore().$reset()
      location.href = "#/login"
    })
    .catch(() => {})
}

const matched = computed(() => {
  return route.matched.filter((item: any) => {
    return item.path !== "/"
  })
})

function findPath(list: any[], path: string, oldPath: string) {
  let num = 0
  for (const item of list) {
    num++
    if (item.redirect === oldPath) {
      return oldPath.replace("/", "#")
    } else if (path.indexOf(item.path) > -1) {
      // 寻到地址
      path = path.replace(item.path, "")
      if (path !== "/") {
        // 还有未替换的
        if (item.children) {
          return findPath(item.children, path, oldPath)
        } else {
          // 地址不符合
          return "#login"
        }
      } else {
        // 地址完全符合
        return oldPath.replace("/", "#")
      }
    } else if (list.length === num) {
      // 未寻到地址
      return "#login"
    }
  }
}

const changeStore = async (e: any) => {
  UserStore().setSroteId(e)
  const menuData = await UserStore().menuInfo(false)
  location.href = findPath(menuData, route.fullPath, route.fullPath) as string
}

// 修改个人信息
const infoShow = ref<boolean>(false)
const changeInfo = () => {
  infoShow.value = true
}
// 修改密码
const pwdShow = ref<boolean>(false)
const changePwd = () => {
  pwdShow.value = true
}
const drwCancel = () => {
  infoShow.value = false
  pwdShow.value = false
}
</script>

<style scoped lang="scss">
.navHeader {
  height: $logoHeight;
  overflow: hidden;
  position: relative;
  background: $backGroundColor;
  border-bottom: 1px solid #e4e7ed;
  box-sizing: border-box;
  display: flex;
  border-radius: 15px;
  margin: 10px 10px 0 10px;

  &__sign {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s;
    cursor: pointer;
    &__icon {
      width: 16px;
      height: 16px;
      padding: 8px;
      background: #f89898;
      border-radius: 12px;
    }
  }

  &__breadcrumb {
    line-height: $logoHeight;
    margin-left: 8px;
  }

  &__menu {
    margin-left: auto;
    height: $logoHeight;
    display: flex;
    align-items: center;

    &__item {
      // width: fit-content;
      height: inherit;
      padding: 0 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      :deep(.el-select__wrapper) {
        border-radius: 15px;
      }
      .el-dropdown {
        height: inherit;
        width: inherit;
        align-items: center;
        justify-content: center;
      }

      .el-dropdown > span {
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
      }

      &--imgSpan {
        height: 100%;
        display: inline-flex;
        align-items: center;
      }
    }

    &__item:hover {
      background: #f6f6f6;

      .img-icon {
        animation: img-icon 1s 1 forwards;
      }
    }

    &--text {
      margin-left: 10px;
      width: 80px;
    }
  }
}

@keyframes img-icon {
  0% {
    transform: scale(0);
  }

  80% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
</style>
