import { createVNode, render } from "vue"
import myLoad from "./loading.vue"
import { i18n } from "@/locales"

const { t } = i18n.global
// 虚拟dom
const show = ref(false)
const tip = ref("")
const vm = createVNode(myLoad, { show: show, tip: tip })

render(vm, document.createElement("div"))

const load = {
  timer: <NodeJS.Timeout | null>null,
  /**
   * @desc 打开loading
   * @param {string} bodyName loading容器，默认'.appmain-container'\
   * @param {String} title 提示内容
   */
  open(args: { bodyName?: string; title?: string } = {}) {
    let { bodyName, title } = args

    if (this.timer) clearInterval(this.timer) // 存在销毁任务
    if (show.value) return // loadign 阻断新loading

    bodyName ?? (bodyName = ".appmain-container")
    title ?? (title = "")

    let targetNode: Element = bodyName === "body" ? document.body : document.querySelector(bodyName) || document.body

    tip.value = title ?? t("tips.loading")

    if (!vm || !vm.el) {
      return
    }

    targetNode.appendChild(vm.el as Element)
    show.value = true
  },
  /**
   * @desc 关闭loading
   * @param {Number} s 延迟关闭秒数
   */
  close(s: number = 1) {
    this.timer = setInterval(() => {
      if (vm?.el && vm.el.parentNode && show.value === true) {
        vm.el.parentNode.removeChild(vm.el)
        show.value = false
      }
    }, s * 1000)
  },
}
export default load
