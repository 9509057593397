<template>
  <el-form :model="pwdForm" ref="pwdRef" label-width="auto" :rules="rules">
    <el-form-item :label="t('user.pwd')" prop="old_password">
      <el-input v-model="pwdForm.old_password" placeholder="" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item :label="t('user.newPwd')" prop="password">
      <el-input v-model="pwdForm.password" placeholder="" type="password" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item :label="t('user.changePwd')" prop="password_confirmation">
      <el-input v-model="pwdForm.password_confirmation" placeholder="" type="password" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>

  <div style="text-align: center">
    <el-button type="primary" @click="confirm(pwdRef)">{{ $t("buttons.confirm") }}</el-button>
    <el-button @click="cancel" :loading="loading">{{ $t("buttons.cancel") }}</el-button>
  </div>
</template>

<script setup lang="ts">
import { changePassword } from "@/api/sys/user"
import { toast } from "@/utils/tip.js"
import { vPwd } from "@/utils/verify"
import type { FormRules, FormInstance } from "element-plus/es"
import { i18n } from "@/locales"
import { UserStore } from "@/stores"

const { t } = i18n.global
const pwdRef = ref()
const pwdForm = reactive({
  old_password: "",
  password: "",
  password_confirmation: "",
})

const checkPass = (rule: any, value: any, callback: any) => {
  if (value === "") {
    callback(new Error(t("tips.rePwd")))
  } else if (value !== pwdForm.password) {
    callback(new Error(t("tips.checkPwd")))
  } else {
    callback()
  }
}
const rules = reactive<FormRules>({
  old_password: [{ required: true, message: t("tips.isRequired"), trigger: "blur" }],
  password: [{ required: true, validator: vPwd, trigger: "blur" }],
  password_confirmation: [{ required: true, validator: checkPass, trigger: "blur" }],
})

const emit = defineEmits(["cancel"])
const cancel = () => {
  emit("cancel")
}
const loading = ref(false)
const confirm = async (pwdRef: FormInstance) => {
  if (!pwdRef) return

  await pwdRef.validate(valid => {
    if (valid) {
      loading.value = true
      changePassword(pwdForm)
        .then(() => {
          toast.success(t("tips.success"))
          loading.value = false
          emit("cancel")
          UserStore().setToken("")
          window.location.reload()
        })
        .catch(e => {
          loading.value = false
        })
    }
  })
}
</script>

<style scoped lang="scss"></style>
